import NotFound from '@/NotFound.vue'
import OrdersDetails from '@/views/Orders/OrdersDetails.vue'
import OrdersView from '@/views/Orders/OrdersView.vue'
import ChangePassword from '@/views/auth-view/ChangePassword.vue'
import LoginView from '@/views/auth-view/LoginView.vue'
import ResetPassword from '@/views/auth-view/ResetPassword.vue'
import CustomerDetails from '@/views/customers/CustomerDetails.vue'
import CustomersView from '@/views/customers/CustomersView.vue'
import DashBoard from '@/views/dashboard/DashBoard.vue'
import BestSelling from '@/views/dashboard/best-selling/BestSelling.vue'
import InvoiceDetails from '@/views/invoices/InvoiceDetails.vue'
import InvoicesView from '@/views/invoices/InvoicesView.vue'
import MenuView from '@/views/menu/MenuView.vue'
import UpdateMenu from '@/views/menu/update-menu/UpdateMenu.vue'
import PaymentView from '@/views/payments/PaymentView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import SetupGuides from '@/views/setup-guides/SetupGuides.vue'
import StoreManagement from '@/views/store-management-analysis/StoreManagement.vue'
import TeamDetails from '@/views/teams/TeamDetails.vue'
import TeamsView from '@/views/teams/TeamsView.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'SetupGuides',
    path: '/setup',
    component: SetupGuides,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'DashBoard',
    path: '/',
    component: DashBoard,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'BestSelling',
    path: '/best-selling',
    component: BestSelling,
    meta: { requiresAuth: true }
  },

  {
    name: 'Orders',
    path: '/orders',
    component: OrdersView,
    meta: { requiresAuth: true }
  },

  {
    name: 'OrderDetails',
    path: '/orders-details/:id',
    component: OrdersDetails,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'Invoices',
    path: '/invoices',
    component: InvoicesView,
    meta: { requiresAuth: true }
  },

  {
    name: 'InvoiceDetails',
    path: '/invoice-details/:id',
    component: InvoiceDetails,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'Menu',
    path: '/menu',
    component: MenuView,
    meta: { requiresAuth: true }
  },

  {
    name: 'UpdateMenu',
    path: '/menu/:id',
    props: true,
    component: UpdateMenu,
    meta: { requiresAuth: true }
  },

  {
    name: 'Customers',
    path: '/customers',
    component: CustomersView,
    meta: { requiresAuth: true }
  },

  {
    name: 'CustomerDetails',
    path: '/customer-details/:id',
    component: CustomerDetails,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'Teams',
    path: '/teams',
    component: TeamsView,
    meta: { requiresAuth: true }
  },

  {
    name: 'TeamDetails',
    path: '/teams/:id',
    component: TeamDetails,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    name: 'Payments',
    path: '/payments',
    component: PaymentView,
    meta: { requiresAuth: true }
  },

  {
    name: 'Settings',
    path: '/settings',
    component: SettingsView
  },

  {
    name: 'StoreManagement',
    path: '/store-management',
    component: StoreManagement
  },

  {
    name: 'Login',
    path: '/auth/login',
    component: LoginView,
  },
  
  {
    name: 'ResetPassword',
    path: '/auth/reset_password',
    component: ResetPassword,
  },

  {
    name: 'ChangePassword',
    path: '/auth/reset-password/:token',
    component: ChangePassword,
    props: true,
  },

 {
    name: 'NotFound',
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
