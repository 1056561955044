import { ref } from "vue";
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'
import UserService from "@/utility/services/users.service";
import { Store } from "vuex";

export interface CustomerDetails {
    name: string | number | undefined;
    email: string;
    role: string;
    status: string;
    lastUpdated: string;
    imageUrl: string
}

export const createUser = async (values: any, store: Store<any>) => {
    try {
        await UserService.createUserRequest(values);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'User Created', x_message: `User "${values.firstName} ${values.lastName}" was created successfully`, duration: 5000 });
    } catch (error: any) {
        // console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error creating user', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const updateUser = async (values: Record<string | number, unknown>, userId: string, store: Store<any>) => {
    try {
        await UserService.updateUserRequest(values, userId);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'User Updated', x_message: `User "${values.firstName} ${values.lastName}" was updated successfully`, duration: 5000 });
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating user', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const getUsers = async ({search = ""}) => {
    try {
        const res = await UserService.getUsersRequest({search}); 
        return res;
    } catch (error) {
        console.error('cannot get users data', error)
    }
}

export const getUserById = async ({userId = ""}) => {
    try {
        const res = await UserService.getUserIdRequest({userId}); 
        return res;
    } catch (error) {
        console.error('cannot get users data', error)
    }
}

export const useCustomersHelper = () => {
    const orderTabs = ref(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
    const orderInformationTabs = ref(['Customer Order', 'Delivery Info']);
    // TODO: add Role & Permissions and Schedules to index 1 and 3 when ready to implement.
    const addMemberTabs = ref(['Basic Information', 'Finance Information', 'Schedules']);

    const activeTab = ref(0);

    const activateTab = (index: number) => {
        activeTab.value = index;
    };

    const customerDetailsData = ref<CustomerDetails>({
        name: '',
        email: '',
        status: '',
        lastUpdated: '',
        role: '',
        imageUrl: ''
    })

    const rolesOptions = [
        { value: "Cleaners", name: "Cleaners" }, 
        { value: "Cashier", name: "Cashier" }, 
        { value: "Chef", name: "Chef" }, 
        { value: "Janitor", name: "Janitor" }, 
        { value: "Manager", name: "Manager" }, 
        { value: "Owner", name: "Owner" }, 
        { value: "Security", name: "Security"}
    ];

    const scheduleHeader = ref([
        {name: 'Start Time', value: 'start'},
        {name: 'End Time', value: 'end'},
        {name: 'Duration', value: 'duration'},
        {name: 'Shift Type', value: 'shift'}
    ])

    const scheduleData = ref([])


    const customersHeader = ref([
        { name: "Member", value: "entityCard", },
        { name: "Role", icon: 'ant-design:sort-ascending-outlined', value: "role" },
        { name: "Status", value: "statusCard" },
        { name: "Last Updated", value: "lastUpdated" },
    ]);

    // const onChangeCategoryData = (event: any) => {
    //     loading.value = true
    //     searchOptionsData.value.category = event.target.value;
    // };

    return {
        activateTab, 
        activeTab, 
        AppWrapper,
        orderTabs,
        customersHeader: customersHeader.value,
        orderInformationTabs,
        customerDetailsData,
        scheduleHeader,
        scheduleData,
        addMemberTabs,
        rolesOptions
    }
}