<template>
    <AppWrapper activeTab="Teams">
        <div class="lg:py-[76px] py-[30px]" v-show="isOpen">
            <div class="lg:flex items-center justify-between">
                <div class="font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between">
                    <div>Teams</div>
                    <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
                </div>
                <div class="lg:flex items-center gap-[12px] grid lg:mt-0 mt-[15px]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                    <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" @change="onSearch"  />
                    <ActionButton theme="primary" label="New Member" class="lg:w-[fit-content]" @click="handleOpenEditModal" />
                    <!-- <AppModal headerContent="Add Member" :openModal="isOpen" @close="handleOpenEditModal">
                        
                    </AppModal> -->
                </div>
            </div>

            <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                <div
                    v-for="(tab, index) in teamTabs"
                    :key="index"
                    :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                    @click="activateTab(index)"
                > {{ tab }} </div>
            </div>

            <div class="my-[28px]">
                <TableField
                    :headers="usersTableHeader"
                    :dataItems="usersTableValue() || []"
                    :isPagination="true"
                    :viewDetails="true"
                    :edit-details="true"
                    :uri-query="queryParams.search"
                    :routeData="{name: 'TeamDetails', paramId: 'id'}"
                />
            </div>
        </div>

        <div v-show="!isOpen" class="w-[800px] m-[auto] py-[72px]">
            <div class="">
                <div class="text-heading-3 flex items-center gap-[24px] mb-[28px]">
                    <div class="cursor-pointer" @click="handleOpenEditModal">
                        <Icon icon="ant-design:arrow-left-outlined" />
                    </div>

                    <div class="font-semibold">Create Member</div>
                </div>
            </div>

            <NewMember :handleOpenEditModal="handleOpenEditModal" />
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import TableField from '@/components/shared/table/TableField.vue';
    import { useCustomersHelper, getUsers } from './Teams.helper'
    import { ref, watchEffect } from 'vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import { SearchOptions } from '../menu/MenuView.vue';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import { Icon } from '@iconify/vue';
    import NewMember from './NewMember.vue'

    const isOpen = ref(true)
    const teamTabs = ref([`Members (0)`]);

    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value

    const queryParams = ref({
        search: '?editModal=true', 
    });
    
    const handleOpenEditModal = () => {
        isOpen.value = !isOpen.value;
    };

    const usersData: any = ref([]);

    const searchOptionsData = ref<SearchOptions>({
        search: '',
    });

    const { AppWrapper, activateTab, activeTab } = useCustomersHelper()
    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const usersTableHeader = ref([
        {name: 'Member', value: 'entityCard'},
        {name: 'Role', icon: 'ant-design:sort-ascending-outlined', value: 'role'},
        {name: 'Status', value: 'status'},
        {name: 'Last Updated', value: 'lastUpdated'},
    ])

    const usersTableValue = () => {
        return usersData.value?.users?.map((user: Record<string | number, unknown | any>) => {
            return {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                imageUrl:`${user.avatar}?v=${new Date().getTime()}`,
                description: user.email,
                subDescription: user.phone,
                role: user.role || 'Not registered',
                status: user.status,
                lastUpdated: user.modifiedDate || '________'
            }
        })
    }

    watchEffect(() => {
        getUsers({search: searchOptionsData.value.search})
        .then((response: any) => {
            usersData.value = response;
            teamTabs.value = [`Members (${response.total})`];
        })
    })

</script>

<style scoped>

</style>