export const sideBarContents: Record<string, { name: string; linkTo: string; icon: string }[]> = {
    'Management': [
        {
            name: 'Setup Guides',
            linkTo: '/setup',
            icon: 'ant-design:rocket-filled'
        },
        {
            name: 'Dashboard',
            linkTo: '/',
            icon: 'ant-design:appstore-filled'
        },
    
        {
            name: 'Orders',
            linkTo: '/orders',
            icon: 'ant-design:shopping-filled'
        },
    
        {
            name: 'Menu',
            linkTo: '/menu',
            icon: 'ant-design:profile-filled'
        },
    
        {
            name: 'Invoices',
            linkTo: '/invoices',
            icon: 'ant-design:file-filled'
        }
    ],

    'Loyalty': [
        {
            name: 'Customers',
            linkTo: '/customers',
            icon: 'ant-design:contacts-filled'
        }
    ],

    'Account': [
        {
            name: 'Teams',
            linkTo: '/teams',
            icon: 'ant-design:idcard-filled'
        },

        // {
        //     name: 'Report and Analysis',
        //     linkTo: '',
        //     icon: 'ant-design:area-chart-outlined'
        // },

        {
            name: 'Payments',
            linkTo: '/payments',
            icon: 'ant-design:credit-card-filled'
        },

        {
            name: 'Settings',
            linkTo: '/settings',
            icon: 'ant-design:setting-filled'
        }
    ]
}

